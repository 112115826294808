import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-116c1132"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "frequency-selector" }
const _hoisted_2 = { class: "frequencies" }
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.humanHzUnits(_ctx.lowerFrequency)), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.humanHzUnits(_ctx.upperFrequency)), 1)
    ]),
    _createElementVNode("div", {
      id: _ctx.drawingZoneId,
      ref: "drawingZone",
      class: "drawing-zone"
    }, null, 8, _hoisted_3)
  ]))
}