
import { Feature, GeometryObject } from 'geojson';
import { LMap, LTileLayer, LGeoJson, Layer } from '@vue-leaflet/vue-leaflet';
import { defineComponent, ref } from 'vue';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';

export default defineComponent({
  name: 'GeoRegionSelecor',
  props: {
    countryStyle: Function
  },
  components: { LMap, LTileLayer, LGeoJson },
  setup (props: Record<string, any>) {
    const fillColor = '#e4ce7f';

    function styleFunction () {
      return () => ({
        weight: 1,
        color: '#ECEFF1',
        opacity: 1,
        fillColor: fillColor,
        fillOpacity: 1
      });
    }

    function onEachFeature (feature: Feature<GeometryObject, any>, layer: Layer) {
      layer.on({
        click: (event: any) => {
          if (event.target && event.target.feature) {
            // console.log('uuu', event.target.feature);
          }
        }
      });
    }

    // TODO
    return {
      loading: ref(true),
      zoom: ref(6),
      center: ref([48, -1.219482]),
      baseLayer: {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      },
      geojsonLayer: ref({
        url: '/data/countries.geojson',
        attribution: '<a href="https://www.naturalearthdata.com/">Natural Earth</a>',
        geojson: undefined,
        options: {
          onEachFeature: onEachFeature,
          style: (feature: Record<string, any>) => {
            if (props.countryStyle) {
              return props.countryStyle(feature);
            } else {
              return {
                fillColor: '#ffaaff',
                opacity: 0.5
              };
            }
          }
        },
        styleFunction: styleFunction
      } as Record<string, any>)
    };
  },
  async mounted () {
    await this.fetchGeojson();
  },
  methods: {
    async fetchGeojson () {
      this.loading = true;
      try {
        const response = await axios.get(this.geojsonLayer.url);
        this.geojsonLayer.geojson = response.data;
      } catch (error) {
        // TODO
        console.log('error', error);
      }
      this.loading = false;
      console.log('xxxiuuu', this.loading);
    },
    onClick (country: Record<string, any>) {
      console.log('xxx', country);
      // this.$emit('countryClick', 'XXX');
    }
  }
});
