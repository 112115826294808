
import IBandGroup from '@/interfaces/IBandGroup';
import { defineComponent, PropType } from 'vue';
import { frequencyToWavelength, humanHzUnits, humanWavelengthUnits } from '@/utils/FrequencyUtils';

export default defineComponent({
  name: 'BandNameChart',
  props: {
    bandGroups: Array as PropType<IBandGroup[]>
  },
  data () {
    return {
      frequency: '0'
    };
  },
  methods: {
    frequencyToOffset (frequency: number): number {
      let offset = Math.log10(frequency);

      if (!isFinite(offset)) {
        offset = 0;
      }

      return offset * 100;
    },
    getBlockWidth (namedBand: Record<string, any>) {
      const logUpperFrequency = Math.log10(namedBand.uf);

      let logLowerFrequency = Math.log10(namedBand.lf);
      if (!isFinite(logLowerFrequency)) {
        logLowerFrequency = 0;
      }
      return Math.round((logUpperFrequency - logLowerFrequency) * 100);
    },
    tooltipForBand (namedBand: Record<string, any>) {
      return `${namedBand.name} (${humanHzUnits(namedBand.lf)} - ${humanHzUnits(namedBand.uf)}, ${humanWavelengthUnits(frequencyToWavelength(namedBand.lf))} - ${humanWavelengthUnits(frequencyToWavelength(namedBand.uf))})`;
    },
    onVisibleSpectrumHover (event) {
      // console.log('event', event.offsetX, event.target.offsetWidth);
    }
  },
  computed: {
    adjustedBandGrops (): IBandGroup[] {
      if (this.bandGroups) {
        return this.bandGroups.map(bandGroup => {
          if (bandGroup.bands.length > 0 && bandGroup.bands[0].lf !== 0) {
            bandGroup.bands = [{
              name: '',
              lf: 0,
              uf: bandGroup.bands[0].lf
            }, ...bandGroup.bands];
          }
          return bandGroup;
        });
      }
      return [];
    },
    visibleSpectrumStyle (): Record<string, any> {
      const baseX = 202;
      const lowerFrequency = 430 * Math.pow(10, 12);
      const upperFrequency = 750 * Math.pow(10, 12);

      const style = {
        left: `${this.frequencyToOffset(lowerFrequency) + baseX}px`,
        width: (this.getBlockWidth({
          lf: lowerFrequency,
          uf: upperFrequency
        }) + 1) + 'px'
      };
      return style;
    }
  }
});
