
import { humanHzUnits, getColourForService } from '@/utils/FrequencyUtils';
import { defineComponent, PropType } from 'vue';
import { FrequencyService, FrequencyAllocationBand } from '../models';

export default defineComponent({
  name: 'AllocationChart',
  props: {
    allocations: {
      type: Array as PropType<FrequencyAllocationBand[]>,
      default: () => [],
      required: true
    },
    serviceFilter: {
      type: Array as PropType<string[] | undefined>,
      required: false
    },
    bandFilter: {
      type: Object as PropType<Record<string, string>>,
      required: false
    }
  },
  setup () {
    const log10bands:FrequencyAllocationBand[] = [];
    log10bands.push({
      lf: 3000,
      uf: 10000
    });
    for (let i = 4; i < 11; i++) {
      log10bands.push({
        lf: Math.pow(10, i),
        uf: Math.pow(10, i + 1)
      });
    }
    log10bands.push({
      lf: Math.pow(10, 11),
      uf: Math.pow(10, 11) * 3
    });

    return {
      log10bands
    };
  },
  methods: {
    allocationBandStyle (allocationBand: FrequencyAllocationBand) {
      // TODO this is still wrong. We also need to work out the right CSS
      const logUpperFrequency = Math.log10(allocationBand.uf);
      const logLowerFrequency = Math.log10(allocationBand.lf || 3000);

      const diff = logUpperFrequency - logLowerFrequency;
      return {
        width: `${diff * 900}px`
      };
    },
    serviceStyle (service: FrequencyService, allocationBand: FrequencyAllocationBand) {
      let colour = getColourForService(service.desc);
      if (!colour) {
        colour = 'white';
      }

      const style: Record<string, string> = {
        background: colour
      };

      const disabledStyle = 'repeating-linear-gradient(45deg, #f5f5f5 0px, #f5f5f5 10px, #fff 10px, #fff 20px)';

      if (Array.isArray(this.serviceFilter) && this.serviceFilter.length > 0) {
        const serviceName = service.desc.toLowerCase();
        const match = this.serviceFilter.find(service => serviceName.startsWith(service));
        if (!match) {
          style.background = disabledStyle;
        }
      }

      if (this.bandFilter && !isNaN(parseFloat(this.bandFilter.lowerFrequency))) {
        const lowerFrequency = parseFloat(this.bandFilter.lowerFrequency as string);
        if (allocationBand.uf < lowerFrequency) {
          style.background = disabledStyle;
        }
      }

      if (this.bandFilter && !isNaN(parseFloat(this.bandFilter.upperFrequency))) {
        const upperFrequency = parseFloat(this.bandFilter.upperFrequency as string);
        if (allocationBand.lf > upperFrequency) {
          style.background = disabledStyle;
        }
      }

      return style;
    },
    humanHzUnits: humanHzUnits
  },
  computed: {
    // allocationBlocks () {
    //   return this.allocations;
    // },
    filteredAllocationBands () {
      let allocations = [...this.allocations];

      const adjustedAllocations = [] as Record<string, any>[];

      if (allocations.length > 0) {
        allocations = allocations.filter((band, idx) => {
          return idx === 0 || (band.services && band.services.length > 0);
        });

        if (allocations[0].lf !== 0) {
          adjustedAllocations.push({
            lf: 0,
            uf: allocations[0].lf
          });
        }

        adjustedAllocations.push(allocations[0]);
        for (let i = 1; i < allocations.length; i++) {
          if (allocations[i - 1].uf !== allocations[i].lf) {
            adjustedAllocations.push({
              lf: allocations[i - 1].uf,
              uf: allocations[i].lf,
              services: [{ desc: 'unspecified' }]
            });
          }
          const services = allocations[i].services;
          if (Array.isArray(services) && services.length > 0) {
            adjustedAllocations.push(allocations[i]);
          }
        }
        if (allocations[allocations.length - 1].uf !== (Math.pow(10, 11) * 3)) {
          adjustedAllocations.push({
            lf: allocations[allocations.length - 1].uf,
            uf: (Math.pow(10, 11) * 3)
          });
        }
      }
      allocations = adjustedAllocations as any;

      if (this.bandFilter && !isNaN(parseFloat(this.bandFilter.lowerFrequency))) {
        const lowerFrequency = parseFloat(this.bandFilter.lowerFrequency as string);
        allocations = allocations.filter(allocationBand => allocationBand.uf >= lowerFrequency);
      }

      if (this.bandFilter && !isNaN(parseFloat(this.bandFilter.upperFrequency))) {
        const upperFrequency = parseFloat(this.bandFilter.upperFrequency as string);
        allocations = allocations.filter(allocationBand => allocationBand.lf <= upperFrequency);
      }

      return allocations;
    }
  }
});
