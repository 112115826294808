
import { defineComponent, ref } from 'vue';
import axios from 'axios';

import FrequencySelector from '@/components/FrequencySelector.vue';
import AllocationChart from '@/components/AllocationChart.vue';
import BandNameChart from '@/components/BandNameChart.vue';
import { FrequencyAllocationBand } from '@/models';
import { humanHzUnits, getColourForService, getServices } from '@/utils/FrequencyUtils';
import GeoRegion from '@/components/GeoRegionSelector.vue';
import IBandGroup from '@/interfaces/IBandGroup';
// import IBand from '@/interfaces/IBand';

// const baseUrl = 'https://www.earthfrequencies.org/api';
// const baseUrl = 'http://localhost:8008/api';
const baseUrl = '/api';

// just until we have a proper data file, and have established
// the json schema we want to use
// const frequencyAllocations: FrequencyAllocations = {
//   name: 'United States',
//   region: 'us',
//   allocationBlocks: [{
//     band: {
//       lower: 3000,
//       upper: 9000
//     },
//     allocations: [{
//       service: 'NOT ALLOCATED',
//       primary: true
//     }]
//   }, {
//     band: {
//       lower: 960000,
//       upper: 116400
//     },
//     allocations: [{
//       service: 'AERONAUTICAL MOBILE (R)',
//       primary: true,
//       footnotes: ['5.327A']
//     }, {
//       service: 'AERONAUTICAL RADIONAVIGATION',
//       primary: true,
//       footnotes: ['5.328']
//     }]
//   }, {
//     band: {
//       lower: 116400,
//       upper: 121500
//     },
//     allocations: [{
//       service: 'AERONAUTICAL RADIONAVIGATION',
//       primary: true,
//       footnotes: ['5.328']
//     }, {
//       service: 'RADIONAVIGATION-SATELLITE (space-to-Earth)',
//       primary: true,
//       footnotes: ['5.328B']
//     }, {
//       service: 'RADIONAVIGATION-SATELLITE (space-to-space)',
//       primary: true,
//       footnotes: ['5.328B']
//     }]
//   }]
// };

export default defineComponent({
  name: 'Home',
  components: {
    FrequencySelector, AllocationChart, GeoRegion, BandNameChart
  },
  setup () {
    const services = getServices();
    return {
      activeRegion: ref('itu1'),
      regions: ref([] as Record<string, string>[]),
      errorMessage: ref(''),
      warningMessage: ref(''),
      allocationBands: ref([] as FrequencyAllocationBand[]),
      metadata: ref(undefined as Record<string, string> | undefined),
      services,
      filterByService: ref('all'),
      filterRange: ref({
        lowerFrequency: '' as number | string,
        upperFrequency: '' as number | string
      }),
      allocationsValid: ref(true),
      bandGroups: ref<IBandGroup[]>([])
    };
  },
  async mounted () {
    await this.fetchNamedBands();
    await this.fetchCharts();
    await this.fetchAllocations();

    // this.bandGroups.push({
    //   name: 'Test Group',
    //   bands: [{
    //     lf: 0,
    //     uf: 3,
    //     name: 'unnamed'
    //   }, {
    //     lf: 3,
    //     uf: 30,
    //     name: 'Extremely Low Frequency'
    //   }, {
    //     lf: 30,
    //     uf: 300,
    //     name: 'Super Low Frequency'
    //   }] as IBand[]
    // } as IBandGroup);

    // this.bandGroups.push({
    //   name: 'Test Group 2',
    //   bands: [{
    //     lf: 0,
    //     uf: 3,
    //     name: 'unnamed',
    //     shortName: ''
    //   }, {
    //     lf: 3,
    //     uf: 30,
    //     name: 'High frequency',
    //     shortName: 'HF'
    //   }, {
    //     lf: 30,
    //     uf: 300,
    //     name: 'Very high frequency',
    //     shortName: 'VHF'
    //   }, {
    //     lf: 300,
    //     uf: 3000,
    //     name: 'Ultra high frequency',
    //     shortName: 'UHF'
    //   }, {
    //     lf: 3000,
    //     uf: 30000,
    //     name: 'Super high frequency',
    //     shortName: 'SHF'
    //   }] as IBand[]
    // } as IBandGroup);
  },
  methods: {
    async fetchNamedBands () {
      try {
        let response = await axios.get(`${baseUrl}/named-bands/`, {
          headers: {
            accept: 'application/json'
          }
        });

        let data = response.data;

        this.bandGroups = [];
        if (data.entries && data.entries.length > 0) {
          const entries = data.entries;
          for (let i = 0; i < entries.length; i++) {
            response = await axios.get(`${baseUrl}/named-bands/${entries[i].id}/`, {
              headers: {
                accept: 'application/json'
              }
            });
            data = response.data;
            this.bandGroups.push(data);
          }
        }
      } catch (error: unknown) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        console.log('error', error);
        this.errorMessage = (error as any).message;
      }
    },
    async fetchCharts () {
      try {
        const response = await axios.get(`${baseUrl}/allocations/tables`, {
          headers: {
            accept: 'application/json'
          }
        });

        const data = response.data;
        console.log('xxxxx', response.data);
        if (data.entries) {
          this.regions = data.entries;
        }
      } catch (error: unknown) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.errorMessage = (error as any).message;
      }
    },
    async fetchAllocations () {
      try {
        this.allocationBands = [];
        this.metadata = {};

        const response = await axios.get(`${baseUrl}/allocations/tables/${this.activeRegion}`);
        const data = response.data;
        if (Array.isArray(data.entries) && data.entries.length > 0) {
          let bands = data.entries[0].bands;
          if (bands[0].lf !== 0) {
            bands = [{ lf: 0, uf: bands[0].lf, services: [] }, ...bands];
          }
          this.allocationBands = data.entries[0].bands;
        }
        this.metadata = data.metadata;
        this.validateAllocations();
      } catch (error: unknown) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.errorMessage = (error as any).message;
      }
    },
    validateAllocations () {
      let valid = true;
      this.warningMessage = '';
      for (let i = 1; i < this.allocationBands.length; i++) {
        if (this.allocationBands[i].lf < this.allocationBands[i - 1].uf) {
          this.warningMessage = 'band allocations data may have issues, due to band overlap';
          valid = false;
        }
        if (this.allocationBands[i].lf !== this.allocationBands[i - 1].uf) {
          this.warningMessage = `band allocations data may have issues, due to missing bands - ${humanHzUnits(this.allocationBands[i].lf)}`;
          valid = false;
        }
      }
      this.allocationsValid = valid;
    },
    async onRegionChange () {
      await this.fetchAllocations();
    },
    humanHzUnits: humanHzUnits,
    getColourForService: getColourForService
  },
  computed: {
    servicesToDisplay (): string[] | undefined {
      if (!this.filterByService || this.filterByService === 'all') {
        return undefined;
      } else {
        return [this.filterByService];
      }
    },
    filteredAllocationBands (): FrequencyAllocationBand[] {
      let allocationBands = this.allocationBands;

      // filter by service type
      if (this.filterByService !== 'all') {
        allocationBands = this.allocationBands.map(allocationBand => {
          const allocationBandClone = Object.assign({}, allocationBand);
          if (allocationBandClone.services) {
            allocationBandClone.services = allocationBandClone.services.filter(service => service.desc.toLowerCase().startsWith(this.filterByService));
          }
          return allocationBandClone;
        });
        allocationBands = allocationBands.filter(allocationBand => (allocationBand.services && allocationBand.services.length > 0));
      }

      // filter by lower frequency
      if (this.filterRange.lowerFrequency && !isNaN(parseFloat(this.filterRange.lowerFrequency as string))) {
        const lowerFrequency = parseFloat(this.filterRange.lowerFrequency as string);
        allocationBands = allocationBands.filter(allocationBand => allocationBand.uf >= lowerFrequency);
      }

      // // filter by upper requency
      if (this.filterRange.upperFrequency && !isNaN(parseFloat(this.filterRange.upperFrequency as string))) {
        const upperFrequency = parseFloat(this.filterRange.upperFrequency as string);
        allocationBands = allocationBands.filter(allocationBand => allocationBand.lf <= upperFrequency);
      }

      return allocationBands;
    }
  }
});
