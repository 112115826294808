import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_easy_spinner = _resolveComponent("easy-spinner")!
  const _component_l_tile_layer = _resolveComponent("l-tile-layer")!
  const _component_l_geo_json = _resolveComponent("l-geo-json")!
  const _component_l_map = _resolveComponent("l-map")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'geo-region-selector': true, loading: _ctx.loading })
  }, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_easy_spinner, {
          key: 0,
          class: "spinner",
          size: "24px"
        }))
      : (_openBlock(), _createBlock(_component_l_map, {
          key: 1,
          zoom: _ctx.zoom,
          center: _ctx.center,
          style: {"height":"100%","width":"100%"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_l_tile_layer, {
              url: _ctx.baseLayer.url,
              attribution: _ctx.baseLayer.attribution
            }, null, 8, ["url", "attribution"]),
            _createVNode(_component_l_geo_json, {
              geojson: _ctx.geojsonLayer.geojson,
              options: _ctx.geojsonLayer.options,
              attribution: _ctx.geojsonLayer.attribution,
              "options-style": _ctx.geojsonLayer.styleFunction
            }, null, 8, ["geojson", "options", "attribution", "options-style"])
          ]),
          _: 1
        }, 8, ["zoom", "center"]))
  ], 2))
}